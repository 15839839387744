import { useEffect, useState } from 'react';
import DestroyButton from '@app/components/destroy-button.tsx';
import { Dialog, DialogPanel } from '@headlessui/react';
import Card from '@app/components/card/card.tsx';
import CardHeader from '@app/components/card/card-header.tsx';
import CardFooter from '@app/components/card/card-footer.tsx';
import PrimaryButton from '@app/components/primary-button.tsx';
import { useParams } from 'react-router-dom';
import useCancelAppointment from '@app/page/appointment/appointment-track/logic/use-cancel-appointment.ts';
import { useTranslate } from '@tolgee/react';
import { CancelReasonEnum } from '@app/graphql/types/graphql.ts';
import { useForm } from 'react-hook-form';
import { captureException } from '@sentry/react';
import FormItem from '@app/components/form/form-item.tsx';
import CardContent from '@app/components/card/card-content.tsx';

interface Reason {
  reasonList: CancelReasonEnum[];
  note: string;
}

const cancelReasonOrder = {
  NO_REASON: 1,
  BUSY: 2,
  NO_INTEREST: 3,
  SOFTWARE_ISSUE: 4,
  OTHER: 5,
};
export default function CancelButtonDialog() {
  const appointmentId = useParams().appointmentId ?? '';

  const [isOpen, setIsOpen] = useState(false);
  const [cancel] = useCancelAppointment();
  const { t } = useTranslate();
  const reasonsList = Object.values(CancelReasonEnum).sort((a, b) => cancelReasonOrder[a] - cancelReasonOrder[b]);

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  useEffect(() => {
    close();
  }, []);

  const form = useForm<Reason>({
    defaultValues: {
      reasonList: [],
      note: '',
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const { register, handleSubmit, watch } = form;

  // const reasonCancel: CancelReasonEnum[] = [];
  const onSubmit = async (data: Reason) => {
    try {
      const result = await cancel({
        variables: {
          id: appointmentId,
          reason: {
            reason: data.reasonList,
            note: data.note,
          },
        },
      });
      if (result.data) {
        setIsOpen(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const watchedReasons = watch('reasonList');
  const noteValue = watch('note', '');

  return (
    <>
      <button
        className={'w-full rounded-md px-2.5 py-1.5 text-sm font-semibold text-red-600 hover:bg-gray-300 sm:w-auto'}
        onClick={open}
      >
        {t('storefront-appointment.track.cancel', 'Cancel appointment')}
      </button>

      <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={close}>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-screen items-center justify-center p-4 text-center backdrop-brightness-75">
            <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-xl p-6 text-left align-middle transition-all">
              <Card>
                <CardHeader title={t('storefront-appointment.track.cancel-dialog.header', 'Cancel appointment')}>
                  {form.formState.errors.reasonList && (
                    <p className="pt-1 text-xs text-red-500">{form.formState.errors.reasonList.message}</p>
                  )}
                </CardHeader>
                <CardContent>
                  <form
                    className="space-y-6"
                    onSubmit={() => {
                      handleSubmit(onSubmit);
                    }}
                  >
                    <FormItem title={t('storefront-appointment.track.cancel-dialog.reason', 'Reason')}>
                      <div className="grid grid-cols-2">
                        {reasonsList.map((reason) => (
                          <div key={reason} className="col-span-2 flex gap-2 truncate px-1 py-2 sm:col-span-1">
                            <input
                              type={'checkbox'}
                              value={reason}
                              {...register('reasonList', {
                                validate: (value) => {
                                  if (value.length === 0) {
                                    return t(
                                      'storefront-appointment.track.cancel-dialog.reason-required',
                                      'Please select at least one reason'
                                    );
                                  }
                                },
                              })}
                              onChange={(e) => {
                                const currentReasons = watchedReasons;
                                if (e.target.checked) {
                                  form.setValue('reasonList', [...currentReasons, reason]);
                                } else {
                                  form.setValue(
                                    'reasonList',
                                    currentReasons.filter((r) => r !== reason)
                                  );
                                }
                              }}
                              className="form-checkbox mt-1 rounded text-indigo-600"
                            />
                            <span className="text-md">
                              {t(`storefront-appointment.track.cancel-dialog.reason.${reason.toLowerCase()}`, reason)}
                            </span>
                          </div>
                        ))}
                      </div>
                    </FormItem>
                    <FormItem
                      subtitle={`(${String(noteValue.length)}/${String(100)})`}
                      title={t('storefront-appointment.track.cancel-dialog.note', 'Note')}
                    >
                      <textarea
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        maxLength={100}
                        placeholder="Details"
                        {...register('note', {
                          maxLength: {
                            value: 100,
                            message: t(
                              'storefront-appointment.track.cancel-dialog.note-max-length',
                              'Note must be less than 100 characters'
                            ),
                          },
                        })}
                      />
                      {form.formState.errors.note && (
                        <p className="pt-1 text-xs text-red-500">{form.formState.errors.note.message}</p>
                      )}
                    </FormItem>
                  </form>
                </CardContent>
                <CardFooter>
                  <div className="flex w-full flex-col items-center justify-between gap-y-4 sm:flex-row">
                    <DestroyButton
                      onClick={() => {
                        handleSubmit(onSubmit)().catch(captureException);
                      }}
                      className="w-full sm:w-auto"
                    >
                      {t('storefront-appointment.track.cancel-dialog.button.cancel', 'Cancel service')}
                    </DestroyButton>
                    <PrimaryButton className="w-full bg-indigo-600 px-6 hover:bg-indigo-400 sm:w-auto" onClick={close}>
                      {t('storefront-appointment.track.cancel-dialog.button.dismiss', 'Dismiss')}
                    </PrimaryButton>
                  </div>
                </CardFooter>
              </Card>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
