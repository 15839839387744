import { ReactNode } from 'react';
import classNames from 'classnames';

export default function DestroyButton(props: {
  id?: string;
  children?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  'data-testid'?: string;
  className?: string;
}) {
  return (
    <button
      id={props.id}
      data-testid={props['data-testid']}
      type="button"
      onClick={props.onClick}
      disabled={props.loading ?? props.disabled}
      className={classNames(
        'rounded-md bg-red-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-700',
        props.className
      )}
    >
      <div className="flex justify-center">{props.children}</div>
    </button>
  );
}
