export interface Service {
  id: string;
  section: string;
  name: string;
  cost: string;
}

const services2: Service[] = [
  // CLASSIC
  { id: 'CLA1', section: 'CLASSIC', name: 'Maniküre inkl. Massage', cost: '18€' },
  { id: 'CLA2', section: 'CLASSIC', name: 'Maniküre inkl. Massage & Lackieren', cost: '25€' },
  { id: 'CLA3', section: 'CLASSIC', name: 'Maniküre inkl. Massage & Shellack', cost: '40€' },
  { id: 'CLA4', section: 'CLASSIC', name: 'Pediküre inkl. Massage & Peeling', cost: '33€' },
  { id: 'CLA5', section: 'CLASSIC', name: 'Pediküre inkl. Massage, Peeling & Lackieren', cost: '43€' },
  { id: 'CLA6', section: 'CLASSIC', name: 'Pediküre inkl. Massage, Peeling & Shellack', cost: '53€' },

  // PULVER - UV GEL SYSTEM
  { id: 'PUL1', section: 'PULVER - UV GEL SYSTEM', name: 'Neumodellage Natur (ohne Tips)', cost: '38€' },
  { id: 'PUL2', section: 'PULVER - UV GEL SYSTEM', name: 'Neumodellage mit French/Farbe (ohne Tips)', cost: '48€' },
  { id: 'PUL3', section: 'PULVER - UV GEL SYSTEM', name: 'Neumodellage Natur (mit Tips)', cost: '43€' },
  { id: 'PUL4', section: 'PULVER - UV GEL SYSTEM', name: 'Neumodellage mit French/Farbe (mit Tips)', cost: '53€' },
  { id: 'PUL5', section: 'PULVER - UV GEL SYSTEM', name: 'Auffüllen Natur', cost: '33€' },
  { id: 'PUL6', section: 'PULVER - UV GEL SYSTEM', name: 'Auffüllen French/Farbe/babyboomer/Glitzer', cost: '43€' },
  { id: 'PUL7', section: 'PULVER - UV GEL SYSTEM', name: 'Flüssig Gel System = Pulver UV System', cost: '+5€' },

  // PERMANENT MAKE UP
  { id: 'PER1', section: 'PERMANENT MAKE UP', name: 'Augenbrauen Microblading', cost: '300€' },
  { id: 'PER2', section: 'PERMANENT MAKE UP', name: 'Augenbrauen Microshading', cost: '400€' },
  { id: 'PER3', section: 'PERMANENT MAKE UP', name: 'Lidstrich oben', cost: '250€' },
  { id: 'PER4', section: 'PERMANENT MAKE UP', name: 'Lidstrich unten', cost: '250€' },
  { id: 'PER5', section: 'PERMANENT MAKE UP', name: 'Lippen (Kontur & Schattierung)', cost: '500€' },
  { id: 'PER6', section: 'PERMANENT MAKE UP', name: 'Remove (Entfernen)', cost: 'ab 150€' },

  // WIMPERNVERLÄNGERUNG
  { id: 'WIM1', section: 'WIMPERNVERLÄNGERUNG', name: 'Neumodellage 2D, 3D, 4D', cost: '99€' },
  { id: 'WIM2', section: 'WIMPERNVERLÄNGERUNG', name: 'Neumodellage Mega Volumen', cost: '119€' },
  { id: 'WIM3', section: 'WIMPERNVERLÄNGERUNG', name: 'Auffüllen bis 2 Wochen', cost: '45€' },
  { id: 'WIM4', section: 'WIMPERNVERLÄNGERUNG', name: 'Auffüllen bis 3 Wochen', cost: '55€' },
  { id: 'WIM5', section: 'WIMPERNVERLÄNGERUNG', name: 'Auffüllen bis 4 Wochen', cost: '65€' },
  { id: 'WIM6', section: 'WIMPERNVERLÄNGERUNG', name: 'Auffüllen bis 5 Wochen', cost: '75€' },
  { id: 'WIM7', section: 'WIMPERNVERLÄNGERUNG', name: 'Wimpernverlängerung Entfernen', cost: 'ab 15€' },
  { id: 'WIM8', section: 'WIMPERNVERLÄNGERUNG', name: 'Wimpernlifting/ lashlifting - inkl. Färben', cost: '50€' },
  { id: 'WIM9', section: 'WIMPERNVERLÄNGERUNG', name: 'Augenbrauenlifting/ browlifting', cost: '50€' },
  { id: 'WIM10', section: 'WIMPERNVERLÄNGERUNG', name: 'Augenbrauen zupfen', cost: '13€' },
  { id: 'WIM11', section: 'WIMPERNVERLÄNGERUNG', name: 'Augenbrauen färben', cost: '13€' },

  // ZEHENMODELLAGEN
  { id: 'ZEH1', section: 'ZEHENMODELLAGEN', name: 'Neumodellage Natur', cost: '48€' },
  { id: 'ZEH2', section: 'ZEHENMODELLAGEN', name: 'Neumodellage French/Farbe', cost: '58€' },
  { id: 'ZEH3', section: 'ZEHENMODELLAGEN', name: 'Auffüllen Natur', cost: '43€' },
  { id: 'ZEH4', section: 'ZEHENMODELLAGEN', name: 'Auffüllen French/Farbe', cost: '48€' },
  { id: 'ZEH5', section: 'ZEHENMODELLAGEN', name: 'Shellack Farbe', cost: '30€' },
  { id: 'ZEH6', section: 'ZEHENMODELLAGEN', name: 'Shellack French (oder mit Flüssigkeit abmachenden)', cost: '35€' },

  // SONSTIGE (SONDERWÜNSCHE NACH AUFWAND UND ABSPRACHE)
  { id: 'SON1', section: 'SONSTIGE (SONDERWÜNSCHE NACH AUFWAND UND ABSPRACHE)', name: 'Kurz Feilen', cost: 'ab 5€' },
  { id: 'SON2', section: 'SONSTIGE (SONDERWÜNSCHE NACH AUFWAND UND ABSPRACHE)', name: 'Lackieren', cost: '10€' },
  {
    id: 'SON3',
    section: 'SONSTIGE (SONDERWÜNSCHE NACH AUFWAND UND ABSPRACHE)',
    name: 'Malen/Sticker',
    cost: 'ab 3€/pro Nagel',
  },
  {
    id: 'SON4',
    section: 'SONSTIGE (SONDERWÜNSCHE NACH AUFWAND UND ABSPRACHE)',
    name: 'Malen/Sticker',
    cost: 'ab 15€ komplett',
  },
  { id: 'SON5', section: 'SONSTIGE (SONDERWÜNSCHE NACH AUFWAND UND ABSPRACHE)', name: '3D Blumen', cost: 'ab 8€' },
  {
    id: 'SON6',
    section: 'SONSTIGE (SONDERWÜNSCHE NACH AUFWAND UND ABSPRACHE)',
    name: 'Strasssteine (weiß/bunt)',
    cost: 'ab 0,5ct',
  },
  {
    id: 'SON7',
    section: 'SONSTIGE (SONDERWÜNSCHE NACH AUFWAND UND ABSPRACHE)',
    name: 'Ein Nagel Extra Farbe/Glitzer/Crom/Schablone',
    cost: '2€',
  },
  { id: 'SON8', section: 'SONSTIGE (SONDERWÜNSCHE NACH AUFWAND UND ABSPRACHE)', name: 'Komplett Crom', cost: '15€' },
  {
    id: 'SON9',
    section: 'SONSTIGE (SONDERWÜNSCHE NACH AUFWAND UND ABSPRACHE)',
    name: 'Komplett Knistern',
    cost: '10€',
  },
  {
    id: 'SON10',
    section: 'SONSTIGE (SONDERWÜNSCHE NACH AUFWAND UND ABSPRACHE)',
    name: 'Komplett Matt/Thermo/Augenkatzen...',
    cost: '5€',
  },
  { id: 'SON11', section: 'SONSTIGE (SONDERWÜNSCHE NACH AUFWAND UND ABSPRACHE)', name: 'Extra Länger', cost: '5€' },
  {
    id: 'SON12',
    section: 'SONSTIGE (SONDERWÜNSCHE NACH AUFWAND UND ABSPRACHE)',
    name: 'Ablösen Shellack',
    cost: '10€',
  },
  {
    id: 'SON13',
    section: 'SONSTIGE (SONDERWÜNSCHE NACH AUFWAND UND ABSPRACHE)',
    name: 'Ablösen Pulver/Gel',
    cost: '15€',
  },
  {
    id: 'SON14',
    section: 'SONSTIGE (SONDERWÜNSCHE NACH AUFWAND UND ABSPRACHE)',
    name: 'Trockene Maniküre',
    cost: '5€',
  },
  { id: 'SON15', section: 'SONSTIGE (SONDERWÜNSCHE NACH AUFWAND UND ABSPRACHE)', name: 'Reparieren', cost: 'ab 5€' },
];

export default services2;