import { useSubscription } from '@apollo/client';
import { graphql } from '@app/graphql/types';

const SUBCRIBE = graphql(`
  subscription Storefront_AppointmentStatusUpdateSubscribe($id: ID!) {
    storefront_appointmentStatusUpdateSubscribe(id: $id) {
      event
      appointment {
        id
        confirmationStatus
      }
    }
  }
`);

export default function useAppointmentStatusUpdateSubscribe(id: string) {
  return useSubscription(SUBCRIBE, {
    variables: { id },
  });
}
