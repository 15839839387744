import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useTranslate } from '@tolgee/react';

const TableReservationCreateBranchError = () => {
  const { t } = useTranslate();
  return (
    <>
      <div className="mx-auto flex max-w-xl items-center justify-center py-32">
        <div className="border-1 mx-2 flex flex-grow flex-col gap-y-8 rounded-md border px-8 py-8 shadow">
          <div className="flex items-center gap-2">
            <ExclamationTriangleIcon className="h-8 w-8 text-center text-red-500" />
            <p className="text-lg font-semibold">{t('reservation.alert-error.title', 'The system is not working')}</p>
          </div>
          <div className="italic text-gray-500">
            {t('reservation.alert-error.description', 'The current business location is temporarily closed')}
          </div>
          <div className="flex justify-end">
            <button
              onClick={() => {
                history.back();
              }}
              className="rounded-md bg-indigo-600 px-3 py-2 font-medium text-white max-sm:grow"
            >
              {t('reservation.alert-error.button', 'Back to home page')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableReservationCreateBranchError;
