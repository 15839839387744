import { useQuery } from '@apollo/client';
import { graphql } from '@app/graphql/types';

export const QUERY = graphql(`
  query storefront_appointmentBranchConfiguration($branchId: ID!) {
    storefront_appointmentBranchConfiguration(branchId: $branchId) {
      hourBlocking
      appointmentLeadMinutes
      appointmentTraitMinutes
      branch {
        ...BranchItem
      }
      menu {
        ...ServiceItem
      }
    }
  }
`);

export function useAppointmentBranchConfiguration(branchId: string) {
  return useQuery(QUERY, { variables: { branchId } });
}
