import { useQuery } from '@apollo/client';
import { graphql } from '@app/graphql/types';

export const QUERY = graphql(`
  query ProductPickerPage($branchID: ID!) {
    storefront_onlineOrderBranchConfiguration(branchID: $branchID) {
      ...OnlineOrderBranchConfigurationItem
    }

    storefront_branch(id: $branchID) {
      ...BranchItem
    }

    storefront_isOnlineOrderOpen(branchID: $branchID) {
      isDeliveryAvailable
      isPickupAvailable
    }
  }
`);

export function useProductPickerQuery(branchID: string | null) {
  return useQuery(QUERY, { variables: { branchID: branchID ?? '' }, skip: !branchID });
}
