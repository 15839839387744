import blackLogo from '../../src/assets/black-logo.png';
import ninetysix from '../../src/assets/ninetysix-black.png';

export default function FooterBar(props: { classNames: string }) {
  return (
    <footer className={props.classNames}>
      <div className="mx-auto w-full max-w-7xl overflow-hidden pb-12 pt-20 sm:pt-24 md:w-3/4 lg:w-3/4 lg:px-8 xl:w-2/3 2xl:w-1/3">
        <div
          aria-label="Footer"
          className="-mb-6 flex flex-wrap items-center justify-center space-x-2 sm:mr-10 sm:flex-nowrap"
        >
          <img className="h-10" src={blackLogo} alt="Digital InHouse 2" />
          <img className="mb-1 h-24 w-auto" src={ninetysix} alt="Digital InHouse 2" />
        </div>

        <p className="mt-4 text-center text-xs leading-5 text-gray-500">
          inHouse is a software product copyrighted by Ninety Six Digital Ltd., Tax code 0110507314, Registration
          Company in Hanoi, Vietnam. Consulting branches in Mannheim, Frankfurt a.M and Munich, Germany.
        </p>
        <p className="text-center text-xs leading-5 text-gray-500">
          {' '}
          Details at:{' '}
          <a className="underline" href="https://www.ninetysixdigital.com/">
            www.ninetysixdigital.com
          </a>
        </p>
      </div>
    </footer>
  );
}
