import { CardSelection, CardSelectionData } from '@app/components/card-selection.tsx';
import { useState } from 'react';
import useFormData from '@app/utils/use-form-data.ts';
import FormLayout from '@app/components/form/form-layout.tsx';
import FormItem from '@app/components/form/form-item.tsx';
import TextInput from '@app/components/form/input/text-input.tsx';
import FormSection from '@app/components/form/form-section.tsx';
import AutofillAddressInput from '@app/page/online-order/fill-contact-info/component/autofill-address-input.tsx';
import CartSideBarPreview from '@app/page/online-order/fill-contact-info/component/cart-side-bar-preview.tsx';
import {
  OnlineOrderBranchConfigurationItemFragment,
  OnlineOrderDeliveryMode,
  OnlineOrderItemFragment,
  OnlineOrderUserState,
} from '@app/graphql/types/graphql.ts';
import { CartSideBarPreviewMode } from './utils/cart-side-bar-preview-mode-enum';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import PhoneInput from 'react-phone-number-input';
import ApplicationErrorView from '@app/module/error/application-error-view.tsx';
import { captureException } from '@sentry/react';
import PrimaryButton from '@app/components/primary-button.tsx';
import { ButtonSize } from '@app/components/button-size.ts';
import './phone-style.css';
import { useOnlineOrderFillPersonalData } from '@app/page/online-order/fill-contact-info/logic/use-online-order-fill-personal-contact.ts';
import { formatGraphQlError, validationErrors } from '@app/module/error/error.ts';
import { formatCurrency } from '@app/components/price/currency-formatter.ts';
import { useCurrency } from '@app/components/price/use-currency.ts';
import { DialogTitle, useClose } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { deliveryDiscountAdditionalText, pickupDiscountAdditionalText } from './utils/pickup-discount-additional-text';
import AlertError from '@app/module/error/alert-error.tsx';
import DialogButton from '@app/components/dialog-button.tsx';
import { useProductPickerQuery } from '@app/page/online-order/product-picker/logic/use-product-picker-page-query.ts';
import { useTranslate } from '@tolgee/react';

interface FormData {
  streetAddress: string;
  streetAddress2: string;
  addressLocality: string;
  addressRegion: string;
  postalCode: string;
  addressCountry: string;

  guestName: string;
  email: string;
  phone: string;
  message: string;
}

const FillContactInfoPage = (props: {
  branchID: string;
  order: OnlineOrderItemFragment;
  onlineOrderBranchConfiguration: OnlineOrderBranchConfigurationItemFragment;
}) => {
  const { t } = useTranslate();
  const currency = useCurrency();
  const { data } = useProductPickerQuery(props.branchID);

  const deliveryModes: CardSelectionData<OnlineOrderDeliveryMode>[] = [
    {
      id: OnlineOrderDeliveryMode.Delivery,
      value: OnlineOrderDeliveryMode.Delivery,
      title: t('online-order.fill-contact-page.form-input.delivery-mode.card-selection.delivery.title', 'Delivery'),
      description: t(
        'online-order.fill-contact-page.form-input.delivery-mode.card-selection.delivery.description',
        'We will deliver the order to your address'
      ),
      additionalText: [
        t(
          'online-order.fill-contact-page.form-input.delivery-mode.card-selection.delivery.addition-text',
          'Minimum order amount for delivery: '
        ) + ` ${formatCurrency(props.onlineOrderBranchConfiguration.minimumOrderAmountForDelivery, currency)}`,
        deliveryDiscountAdditionalText(
          props.onlineOrderBranchConfiguration.deliveryDiscountAmount,
          props.onlineOrderBranchConfiguration.deliveryDiscountType,
          currency
        ),
      ],
    },
    {
      id: OnlineOrderDeliveryMode.Pickup,
      value: OnlineOrderDeliveryMode.Pickup,
      title: t('online-order.fill-contact-page.form-input.delivery-mode.card-selection.pickup.title', 'Pickup'),
      description: t(
        'online-order.fill-contact-page.form-input.delivery-mode.card-selection.pickup.description',
        'You will pick up the order at the store'
      ),
      additionalText: [
        pickupDiscountAdditionalText(
          props.onlineOrderBranchConfiguration.pickupDiscountAmount,
          props.onlineOrderBranchConfiguration.pickupDiscountType,
          currency
        ),
      ],
    },
  ];

  // Params
  const availableDeliveryModes: CardSelectionData<OnlineOrderDeliveryMode>[] = [];

  if (data?.storefront_isOnlineOrderOpen.isDeliveryAvailable) {
    availableDeliveryModes.push(deliveryModes[0]);
  }

  if (data?.storefront_isOnlineOrderOpen.isPickupAvailable) {
    availableDeliveryModes.push(deliveryModes[1]);
  }

  let initialDeliveryMode = availableDeliveryModes.length > 0 ? availableDeliveryModes[0] : deliveryModes[1];
  if (props.order.deliveryMode) {
    initialDeliveryMode = deliveryModes.find((mode) => mode.value === props.order.deliveryMode) ?? deliveryModes[0];
  }

  // Hooks
  const [selectedDeliveryMode, setSelectedDeliveryMode] = useState(initialDeliveryMode);

  const [changeUserState, { loading, error }] = useOnlineOrderFillPersonalData();

  const {
    data: form,
    handle,
    manualHandle,
  } = useFormData<FormData>({
    streetAddress: props.order.streetAddress ?? '',
    streetAddress2: props.order.streetAddress2 ?? '',
    addressLocality: props.order.addressLocality ?? '',
    addressRegion: props.order.addressRegion ?? '',
    postalCode: props.order.postalCode ?? '',
    addressCountry: props.order.addressCountry ?? '',

    guestName: props.order.guestName ?? '',
    email: props.order.email ?? '',
    phone: props.order.phone ?? '',
    message: props.order.message ?? '',
  });

  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  const saveAddressAndChangeState = (nextUserState: OnlineOrderUserState) => {
    changeUserState({
      variables: {
        onlineOrderID: props.order.id,
        input: {
          deliveryMode: selectedDeliveryMode.value,

          streetAddress: form.streetAddress,
          streetAddress2: form.streetAddress2,
          addressLocality: form.addressLocality,
          addressRegion: form.addressRegion,
          postalCode: form.postalCode,
          addressCountry: form.addressCountry,

          guestName: form.guestName,
          email: form.email,
          phone: form.phone,
          message: form.message,

          nextUserState: nextUserState,
        },
      },
    }).catch(captureException);
  };

  const backToEditCartStateHandler = () => {
    saveAddressAndChangeState(OnlineOrderUserState.PickingProduct);
  };

  const reviewDisable = () => {
    if (loading) {
      return true;
    }

    if (selectedDeliveryMode.value === OnlineOrderDeliveryMode.Delivery) {
      if (props.onlineOrderBranchConfiguration.minimumOrderAmountForDelivery > (props.order.basePrice ?? 0)) {
        return true;
      }
    }
    return false;
  };

  const reviewHandler = () => {
    saveAddressAndChangeState(OnlineOrderUserState.Review);
  };
  return (
    <div className="py-8">
      <div className="mb-4 flex cursor-pointer px-4 text-gray-500 hover:underline" onClick={backToEditCartStateHandler}>
        {t('online-order.fill-contact-page.back-to-edit-cart', 'Back to edit cart')}
        <ChevronLeftIcon className="w-6" />
      </div>

      {Object.values(validationError).length == 0 && <ApplicationErrorView className="p-2 lg:p-4" error={error} />}

      <div className="mx-4 grid max-w-7xl grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16 xl:mx-auto">
        <div>
          <CartSideBarPreview
            order={props.order}
            onlineOrderBranchConfiguration={props.onlineOrderBranchConfiguration}
            deliveryMode={selectedDeliveryMode.value}
            mode={CartSideBarPreviewMode.OnlyProducts}
          />
        </div>

        <div className="">
          <p className="font-semibol4 mb-4 text-3xl">{t('online-order.fill-contact-page.fill-data', 'Fill data')}</p>

          <FormLayout>
            <div className="space-y-12">
              <div className="border-b border-gray-900/10 pb-12">
                {/*Delivery mode*/}
                <CardSelection
                  title={t('online-order.fill-contact-page.form-input.delivery-mode.label', 'Delivery mode')}
                  data={availableDeliveryModes}
                  value={selectedDeliveryMode}
                  onChange={setSelectedDeliveryMode}
                />
              </div>
            </div>

            {selectedDeliveryMode.value === OnlineOrderDeliveryMode.Delivery && (
              <FormSection
                title={t('online-order.fill-contact-page.form-input.delivery.address-title', 'Delivery address')}
              >
                <AutofillAddressInput
                  values={{ ...form }}
                  onChange={handle}
                  streetAddress={(value) => {
                    manualHandle('streetAddress', value);
                  }}
                  error={validationError}
                />
                <div className="mt-2">
                  {error?.graphQLErrors.map((item, index) => (
                    <AlertError key={index} title="Error" message={item.message} />
                  ))}
                </div>
              </FormSection>
            )}

            <FormSection
              title={t('online-order.fill-contact-page.form-input.delivery.contact-data.title', 'Contact data')}
            >
              <div className="flex flex-col space-y-4">
                {/*Name*/}
                <FormItem
                  title={t('online-order.fill-contact-page.form-input.delivery.contact-data.name.label', 'Your name')}
                  className="mp-2 max-w-md"
                >
                  <TextInput
                    type="text"
                    label="guestName"
                    value={form.guestName}
                    name="guestName"
                    placeholder={t(
                      'online-order.fill-contact-page.form-input.delivery.contact-data.name.placeholder',
                      'Name'
                    )}
                    onChange={handle}
                    error={validationError.guestName}
                  />
                </FormItem>

                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                  {/* Email */}
                  <FormItem
                    title={t(
                      'online-order.fill-contact-page.form-input.delivery.contact-data.email.label',
                      'Your email address'
                    )}
                    className="max-w-md"
                  >
                    <TextInput
                      type="text"
                      label="email"
                      value={form.email}
                      name="email"
                      placeholder={t(
                        'online-order.fill-contact-page.form-input.delivery.contact-data.email.placeholder',
                        'Email'
                      )}
                      onChange={handle}
                      error={validationError.email}
                    />
                  </FormItem>

                  {/*Phone*/}
                  <FormItem
                    title={t(
                      'online-order.fill-contact-page.form-input.delivery.contact-data.phone.label',
                      'Your phone number'
                    )}
                    className="max-w-md"
                  >
                    <PhoneInput
                      className="block w-full overflow-hidden rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultCountry={'DE'}
                      placeholder={t(
                        'online-order.fill-contact-page.form-input.delivery.contact-data.phone.placeholder',
                        'Enter phone number'
                      )}
                      value={form.phone}
                      onChange={(value) => {
                        manualHandle('phone', value?.toString() ?? '');
                      }}
                    />
                    {validationError.phone && (
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {validationError.phone}
                      </p>
                    )}
                  </FormItem>
                </div>

                {/*Message*/}
                <FormItem
                  title={t('online-order.fill-contact-page.form-input.delivery.contact-data.message.label', 'Message')}
                  className="max-w-md"
                >
                  <div className="mt-2">
                    <textarea
                      rows={4}
                      name="message"
                      id="message"
                      className="bloc k w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(newVal) => {
                        manualHandle('message', newVal.target.value);
                      }}
                      value={form.message}
                    />
                  </div>
                  {validationError.message && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {validationError.message}
                    </p>
                  )}
                </FormItem>
              </div>
            </FormSection>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              {selectedDeliveryMode.value === OnlineOrderDeliveryMode.Delivery &&
              props.onlineOrderBranchConfiguration.minimumOrderAmountForDelivery > (props.order.basePrice ?? 0) ? (
                <DialogButton
                  content={
                    <div>
                      <AlertButton
                        backToEditCart={backToEditCartStateHandler}
                        minimumPrice={formatCurrency(
                          props.onlineOrderBranchConfiguration.minimumOrderAmountForDelivery,
                          currency
                        )}
                      />
                    </div>
                  }
                >
                  <PrimaryButton buttonSize={ButtonSize.large}>
                    {t('online-order.fill-contact-page.review.button', 'Review')}
                  </PrimaryButton>
                </DialogButton>
              ) : (
                <PrimaryButton buttonSize={ButtonSize.large} disabled={reviewDisable()} onClick={reviewHandler}>
                  {t('online-order.fill-contact-page.review.button', 'Review')}
                </PrimaryButton>
              )}
            </div>
          </FormLayout>
        </div>
      </div>
    </div>
  );
};

function AlertButton({
  backToEditCart,
  minimumPrice,
}: {
  backToEditCart: () => void;
  minimumPrice: string;
}): JSX.Element {
  const close: () => void = useClose();
  const { t } = useTranslate();
  return (
    <div>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
            {t('online-order.fill-contact-page.review.alert.title', 'Review fail')}
          </DialogTitle>
          <div className="mt-2">
            <p className="text-md text-gray-500">
              {t('online-order.fill-contact-page.review.alert.description', 'The minimum for delivery is not reached ')}
              <span className="text-lg text-gray-600">{minimumPrice}</span>{' '}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          onClick={backToEditCart}
          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
        >
          {t('online-order.fill-contact-page.review.alert.button.back-to-edit-cart', 'Back to edit cart')}
        </button>
        <button
          type="button"
          onClick={() => {
            close();
          }}
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        >
          {t('online-order.fill-contact-page.review.alert.button.cancel', 'Cancel')}
        </button>
      </div>
    </div>
  );
}

export default FillContactInfoPage;
