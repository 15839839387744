import Card from '@app/components/card/card.tsx';
import CardContent from '@app/components/card/card-content.tsx';
import { BranchItemFragment, PickedProductInput, ServiceItemFragment } from '@app/graphql/types/graphql.ts';
import { getFragmentData } from '@app/graphql/types';
import { ServiceProductFragment } from '@app/page/online-order/model/appointment-fragment.ts';
import { MenuSectionFragment } from '@app/page/online-order/model/menu-section-fragment.ts';
import {
  MenuProductConfigurationFragment,
  MenuProductConfigurationValueFragment,
} from '@app/page/online-order/model/menu-product-fragment.ts';
import { formatRawCurrency } from '@app/components/price/currency-formatter.ts';
import {
  AppointmentMonitor,
  useAppointmentInfo,
} from '@app/page/appointment/appointment-create/logic/use-appointment-info.ts';
import { useTranslate } from '@tolgee/react';
import getImageLink from '@app/utils/get-image-link.ts';

export default function AppointmentServiceStart(props: { menu: ServiceItemFragment; branch: BranchItemFragment }) {
  const { branch, menu } = props;
  const selectProduct = useAppointmentInfo((state) => state.selectProduct);
  const setAppointmentMonitor = useAppointmentInfo((state) => state.setAppointmentMonitor);
  const { t } = useTranslate();
  const handleClick = (pickedProduct: PickedProductInput) => {
    selectProduct(pickedProduct);
    setAppointmentMonitor(AppointmentMonitor.time);
  };

  return (
    <div className="flex w-full flex-col items-center bg-gray-100 sm:justify-center">
      <Card className="min-w-full sm:mt-24 sm:min-w-[750px]">
        <CardContent>
          <div>
            <header className="mb-10 flex w-full items-center justify-between">
              {/*Logo*/}
              {branch.company.logo ? (
                <img
                  alt="logo"
                  src={getImageLink(branch.company.logo)}
                  className="h-12 w-auto object-contain pl-4 sm:h-20"
                />
              ) : (
                <div></div>
              )}

              {/*Branch information*/}
              <div className="flex scale-75 flex-col items-end text-right text-gray-500 sm:scale-100">
                <p className="text-xl">{branch.name}</p>
                <p className="text-sm">{`${branch.streetAddress}, ${branch.postalCode} ${branch.addressLocality}`}</p>
                <p className="text-sm">{branch.website}</p>
              </div>
            </header>
            <section>
              <ul className="flex flex-col gap-8">
                {menu.sections.map((sectionFragment) => {
                  // get section fragment
                  const section = getFragmentData(MenuSectionFragment, sectionFragment);

                  // product list interface
                  const menuProductList = menu.menuProducts
                    .filter((productFragment) => {
                      const product = getFragmentData(ServiceProductFragment, productFragment);
                      return product.sectionId == section.id;
                    })
                    .map((productFragment) => {
                      const product = getFragmentData(ServiceProductFragment, productFragment);
                      const configuration = getFragmentData(MenuProductConfigurationFragment, product.configurations);
                      const configurationValue = getFragmentData(
                        MenuProductConfigurationValueFragment,
                        configuration[0].values
                      );
                      const currencyData = formatRawCurrency(
                        configurationValue[0].price,
                        branch.company.settings.currency
                      );

                      return (
                        <li
                          key={product.id}
                          className="flex w-full items-center justify-between rounded-xl bg-stone-100 px-4 py-3 hover:bg-stone-200"
                          onClick={() => {
                            // Covert from raw product to pickedProduct
                            const convertToPickedProductData: PickedProductInput = {
                              productID: product.id,
                              pickedConfiguration: [
                                {
                                  configurationID: configuration[0].id,
                                  valueID: configurationValue[0].id,
                                },
                              ],
                            };

                            handleClick(convertToPickedProductData);
                          }}
                        >
                          <div className="space-y-2">
                            <p className="text-sm">{product.title}</p>
                            <p className="mr-8 line-clamp-2 max-w-xl text-ellipsis text-xs text-gray-700">
                              {product.description}
                            </p>
                            <p className="text-xs text-gray-500">
                              {product.appointmentMenuProductData.duration}{' '}
                              {t('storefront-appointment.create.minutes', 'Minutes')}
                            </p>
                          </div>

                          <div>
                            <p className="text-base text-gray-500">{currencyData.value + currencyData.symbol}</p>
                          </div>
                        </li>
                      );
                    });

                  if (menuProductList.length == 0) {
                    return null;
                  }

                  return (
                    <li key={section.id}>
                      <p className="mb-2 ml-4 text-sm text-gray-600">{section.name.toUpperCase()}</p>
                      <ul className="flex flex-col gap-2">{menuProductList}</ul>
                    </li>
                  );
                })}
              </ul>
            </section>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
