import { useQuery } from '@apollo/client';
import { graphql } from '../../../graphql/types';

export const QUERY = graphql(`
  query ActiveTableReservationBranch($companyID: ID!) {
    storefront_tableReservationBranches(companyId: $companyID) {
      id
      name
      website
      streetAddress
      addressLocality
      postalCode
      tableReservationSetting {
        seatLimit
        phone
        status
        flexDuration
        flexEnabled
      }
      company {
        logo
        settings {
          customerDefaultLanguage
          timezone
        }
      }
    }
  }
`);

export function useGetBranches(id: string | null) {
  return useQuery(QUERY, { variables: { companyID: id ?? '' }, skip: !id });
}
