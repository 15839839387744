import { ReactNode } from 'react';
import { To, useNavigate } from 'react-router-dom';
import { ChevronLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

export default function CardHeader(props: {
  title: string;
  withBackButton?: boolean;
  handleBackButtonClick?: () => void;
  onCrossButton?: () => void;
  backTarget?: string | To | number;
  hideTitle?: boolean;
  children?: ReactNode;
}) {
  const navigate = useNavigate();

  return (
    <div className="px-2 py-4 md:px-4">
      <div className="sm:flex sm:items-center">
        {!props.hideTitle ? (
          <div className="sm:flex-auto flex items-center">
            {props.withBackButton && (
              <button
                data-testid="back-button"
                onClick={() => {
                  if (props.handleBackButtonClick) {
                    props.handleBackButtonClick();
                    return;
                  }

                  if (typeof props.backTarget === 'number') {
                    navigate(props.backTarget);
                  } else {
                    navigate(props.backTarget ?? '..');
                  }
                }}
              >
                <ChevronLeftIcon className="w-6 mr-2" />
              </button>
            )}
            <h1
              className={classNames('text-2xl font-semibold leading-6 text-gray-900', {
                'ml-1': !props.withBackButton,
              })}
            >
              {props.title}
            </h1>

            {props.onCrossButton && (
              <>
                <div className="flex-1" />
                <XMarkIcon
                  onClick={() => {
                    props.onCrossButton?.();
                  }}
                  className="w-8 h-8"
                />
              </>
            )}
          </div>
        ) : (
          <div className="flex-1" />
        )}

        {props.children && (
          <div
            className={classNames('flex sm:ml-16 sm:mt-0 xs:flex-none gap-4 flex-wrap items-center', {
              'mt-4': !props.hideTitle,
            })}
          >
            {props.children}
          </div>
        )}
      </div>
    </div>
  );
}
