import { graphql } from '@app/graphql/types';

export const ServiceFragment = graphql(`
  fragment ServiceItem on ServiceMenu {
    id
    title
    menuProducts {
      ...ServiceProductItem
    }
    sections {
      ...MenuSectionItem
    }
  }
`);

export const ServiceProductFragment = graphql(`
    fragment ServiceProductItem on NailAppointmentMenuProduct {
        id
        title
        code
        description
        images
        enabled
        section {
            ...MenuSectionItem
        }
        configurations {
            ...MenuProductConfigurationItem
        }

        appointmentMenuProductData {
            menuProductId
            duration
            nailAppointmentMenuProductTeams {
                id
                team {
                    id
                    teamMember {
                        id
                        user {
                            firstName
                            lastName
                        }
                    }
                }
            }
        }
        menuId
        sectionId
    }
`);
