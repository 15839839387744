import { useSearchParams } from 'react-router-dom';
import { useTableReservation } from './logic/use-table-reservation.ts';
import { useEffect } from 'react';
import NailReservationProcess from './nail-component/nail-reservation-process.tsx';

export default function NailTableReservationDetail() {
  const [searchParams] = useSearchParams();
  const tableReservationId = searchParams.get('tableReservationId') ?? '';
  const branchID = searchParams.get('branchID');

  const { data, reservation } = useTableReservation(tableReservationId);

  useEffect(() => {
    document.title = 'Table Reservation Detail';
  }, []);

  return (
    <>
      <div className="flex h-screen w-full flex-col items-center justify-center">
        {<NailReservationProcess branchID={branchID} reservation={reservation} data={data} />}
      </div>
    </>
  );
}
