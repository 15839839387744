import { DatePicker } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { DaysOfWeek } from '../../../graphql/types/graphql.ts';
import { DisabledTimes, IntRange } from 'rc-picker/lib/interface';
import { useGetBranchOpeningTime } from '../logic/use-get-branch-opening-times.ts';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useTranslate } from '@tolgee/react';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import useHolidays from '@app/page/appointment/appointment-create/logic/use-holidays.ts';

dayjs.extend(utc);
dayjs.extend(timezone);

const DateInput = (props: {
  handleFunction: (name: string, value: string | number) => void;
  attrName: string;
  branchID: string;
}) => {
  const { handleFunction, attrName } = props;

  const { data } = useGetBranchOpeningTime(props.branchID);
  const { data: holidayData } = useHolidays(props.branchID);

  // @ts-expect-error wrong type from library
  const minuteStep: IntRange<1, 59> = 60 / (data?.storefront_tableReservationConfiguration.hourBlocking ?? 4);

  const [currentTimezone, setTimezone] = useState<string>('Europe/Berlin');
  const [date, setDate] = useState<Dayjs>(dayjs());

  const [disabledTime, setDisabledTime] = useState<((date: Dayjs) => DisabledTimes) | undefined>();

  const timeHandler = (time: string) => {
    setDate((date) => date.set('hour', parseInt(time.split(':')[0])).set('minute', parseInt(time.split(':')[1])));

    const analytics = getAnalytics();

    const newDate = date.set('hour', parseInt(time.split(':')[0])).set('minute', parseInt(time.split(':')[1]));
    handleFunction(attrName, moment(newDate.toDate()).tz(currentTimezone).format('YYYY-MM-DDTHH:mm:ss'));

    logEvent(analytics, 'select_time_block', {});
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    const branch = data.storefront_tableReservationConfiguration.branch;
    setTimezone(branch.company.settings.timezone);

    const newDate = dayjs()
      .tz(branch.company.settings.timezone)
      .add(1, 'hour')
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0);

    setDate(newDate);
  }, [data, setDate]);

  useEffect(() => {
    const reservationLeadMinutes = data?.storefront_tableReservationConfiguration.reservationLeadMinutes ?? 0;
    const reservationTraitMinutes = data?.storefront_tableReservationConfiguration.reservationTraitMinutes ?? 0;
    const now = dayjs.tz(dayjs(), currentTimezone);
    const isToday = moment(date.toISOString()).tz(currentTimezone).diff(moment.tz(currentTimezone), 'day') == 0;

    const fc = (date: Dayjs): DisabledTimes => {
      const holidays =
        holidayData?.storefront_holidays.filter((holiday) => {
          const startTime = moment(holiday.startTime).tz(currentTimezone).format('YYYY-MM-DD');

          const endTime = moment(holiday.endTime).tz(currentTimezone).format('YYYY-MM-DD');
          const today = moment(date.toISOString()).tz(currentTimezone).format('YYYY-MM-DD');

          if (moment(today).isBetween(startTime, endTime, undefined, '[]')) {
            return true;
          }
        }) ?? [];

      const openingTimeToday =
        data?.storefront_openingTimes.filter((op) => {
          return op.dayOfWeek === currentDayOfWeek(date);
        }) ?? [];

      // Disable all time if pause is true and today
      if (data?.storefront_tableReservationConfiguration.pause && isToday) {
        return {
          disabledHours: () => Array.from({ length: 24 }, (_, i) => i),
          disabledMinutes: () => Array.from({ length: 60 }, (_, i) => i),
        };
      }

      // Disable all time if no opening time today
      return {
        disabledHours: () => {
          let bannedHours = Array.from({ length: 24 }, (_, i) => i);

          for (const openingTime of openingTimeToday) {
            bannedHours = bannedHours.filter((hour) => {
              const openTime = now
                .set('hour', parseInt(openingTime.openTime.slice(0, 2)))
                .set('minute', parseInt(openingTime.openTime.slice(3, 5)));
              const closeTime = now
                .set('hour', parseInt(openingTime.closeTime.slice(0, 2)))
                .set('minute', parseInt(openingTime.closeTime.slice(3, 5)));

              let openHour = parseInt(openingTime.openTime.slice(0, 2));
              const closeHour = closeTime.subtract(reservationTraitMinutes, 'minutes').hour();

              if (isToday) {
                // Special check for today, adjust opening time
                if ((now < openTime && openTime.diff(now, 'minute') < reservationLeadMinutes) || now >= openTime) {
                  openHour = now.add(reservationLeadMinutes, 'minutes').hour();
                }
              }

              return hour < openHour || hour > closeHour;
            });
          }

          for (const holiday of holidays) {
            const startTime = moment(holiday.startTime).tz(currentTimezone);
            const endTime = moment(holiday.endTime).tz(currentTimezone);

            const openHour = startTime.get('hour');
            const closeHour = endTime.get('hour');
            const holidayBannedHours = Array.from({ length: 24 }, (_, i) => i);

            const holidayBannedHoursFilter = holidayBannedHours.filter((item) => item > openHour && item < closeHour);
            holidayBannedHoursFilter.forEach((item) => {
              if (!bannedHours.includes(item)) {
                bannedHours.push(item);
              }
            });
          }

          return bannedHours;
        },
        disabledMinutes: (hour: number) => {
          let bannedMinutes = Array.from({ length: 60 }, (_, i) => i);

          for (const openingTime of openingTimeToday) {
            bannedMinutes = bannedMinutes.filter((bannedHour) => {
              const openTime = now
                .set('hour', parseInt(openingTime.openTime.slice(0, 2)))
                .set('minute', parseInt(openingTime.openTime.slice(3, 5)));
              const closeTime = now
                .set('hour', parseInt(openingTime.closeTime.slice(0, 2)))
                .set('minute', parseInt(openingTime.closeTime.slice(3, 5)));

              let openHour = parseInt(openingTime.openTime.slice(0, 2));
              const closeHour = closeTime.subtract(reservationTraitMinutes, 'minutes').hour();

              let openMinute = parseInt(openingTime.openTime.slice(3, 5));
              const closeMinute = closeTime.subtract(reservationTraitMinutes, 'minutes').minute();

              if (isToday) {
                if ((now < openTime && openTime.diff(now, 'minute') < reservationLeadMinutes) || now >= openTime) {
                  openHour = now.add(reservationLeadMinutes, 'minutes').hour();

                  openMinute = now.add(reservationLeadMinutes, 'minutes').minute();
                }
              }
              return (
                hour < openHour ||
                hour > closeHour ||
                (hour === openHour && bannedHour < openMinute) ||
                (hour === closeHour && bannedHour > closeMinute)
              );
            });
          }

          for (const holiday of holidays) {
            const startTime = moment(holiday.startTime).tz(currentTimezone);
            const endTime = moment(holiday.endTime).tz(currentTimezone);

            const openHour = startTime.get('hour');
            const closeHour = endTime.get('hour');

            const openMinute = startTime.get('minutes');
            const closeMinute = endTime.get('minutes');

            const holidayBannedMinutes = Array.from({ length: 60 }, (_, i) => i);

            const holidayBannedMinutesFilter1 = holidayBannedMinutes.filter((item) => item >= openMinute);

            const holidayBannedMinutesFilter2 = holidayBannedMinutes.filter((item) => item < closeMinute);

            if (hour == openHour) {
              holidayBannedMinutesFilter1.forEach((item) => {
                bannedMinutes.push(item);
              });
            }

            if (hour == closeHour) {
              holidayBannedMinutesFilter2.forEach((item) => {
                bannedMinutes.push(item);
              });
            }
          }

          return bannedMinutes;
        },
      };
    };

    setDisabledTime(() => fc);
  }, [
    holidayData?.storefront_holidays,
    data?.storefront_openingTimes,
    data?.storefront_tableReservationConfiguration.pause,
    data?.storefront_tableReservationConfiguration.reservationLeadMinutes,
    data?.storefront_tableReservationConfiguration.reservationTraitMinutes,
    currentTimezone,
    date,
  ]);

  const disabledDate = (current: Dayjs) => {
    const isToday = moment(date.toISOString()).tz(currentTimezone).diff(moment.tz(currentTimezone), 'day') == 0;

    if (data?.storefront_tableReservationConfiguration.pause) {
      if (isToday) {
        return true;
      }
    }

    const openingTimes =
      data?.storefront_openingTimes.filter((op) => {
        return op.dayOfWeek === currentDayOfWeek(current);
      }) ?? [];

    return openingTimes.length === 0;
  };

  const handleDateIncrementAndDecrement = (action: string) => {
    switch (action) {
      case 'increment':
        setDate(date.add(1, 'day'));
        return;
      case 'decrement':
        if (!isPreviousValid(date)) {
          setDate(date.subtract(1, 'day'));
        }
        return;
      case 'today':
        setDate(dayjs());
        return;
      default:
        throw new Error('Wrong action!!');
    }
  };
  const { t } = useTranslate();

  return (
    <div>
      <div>
        <label className="mb-1 block text-sm font-medium leading-6 text-gray-900">
          {t('reservation.create-form.form-input.date.label', 'Date')}
        </label>
        <DatePicker
          className="w-full py-2"
          onChange={(date) => {
            setDate(date);
            handleFunction(attrName, '');
          }}
          value={date}
          size={'large'}
          minDate={dayjs()}
          disabledDate={disabledDate}
          needConfirm={false}
          allowClear={false}
        />
      </div>

      <div className="grid grid-cols-3 gap-2 pt-2">
        <div
          onClick={() => {
            handleDateIncrementAndDecrement('decrement');
          }}
          className={classNames(
            'flex cursor-pointer items-center justify-center rounded px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
            {
              'bg-gray-100 hover:bg-gray-100': isPreviousValid(date),
            }
          )}
        >
          {t('reservation.create-form.form-input.date.button.previous', 'Previous')}
        </div>
        <div
          onClick={() => {
            setDate(dayjs());
          }}
          className={classNames(
            'flex cursor-pointer items-center justify-center rounded px-2 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-400',
            {
              'bg-indigo-600 text-white': date.isSame(dayjs(), 'date'),
            }
          )}
        >
          {t('reservation.create-form.form-input.date.button.today', 'Today')}
        </div>

        <div
          onClick={() => {
            handleDateIncrementAndDecrement('increment');
          }}
          className="flex cursor-pointer items-center justify-center rounded px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          {t('reservation.create-form.form-input.date.button.next', 'Next')}
        </div>
      </div>

      <div className="pt-6">
        <label className="mb-1 block text-sm font-medium leading-6 text-gray-900">
          {t('reservation.create-form.form-input.date.time', 'Time')}
        </label>
        <TimeSlots
          value={date}
          disabledDate={disabledTime}
          minuteStep={minuteStep}
          onTimeChange={(time) => {
            timeHandler(time);
          }}
        />
      </div>
    </div>
  );
};

const TimeSlots = (props: {
  value: Dayjs;
  disabledDate: ((date: Dayjs) => DisabledTimes) | undefined;
  onTimeChange?: (time: string) => void;
  minuteStep: number;
}) => {
  const { value, disabledDate, minuteStep } = props;

  const { t } = useTranslate();

  const disabledTimes = useMemo(() => {
    return disabledDate ? disabledDate(value) : undefined;
  }, [disabledDate, value]);

  // Generate all time slots & filter
  const timeSlots: string[] = useMemo(() => {
    const timeSlots: string[] = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += minuteStep) {
        // Check disable time
        if (disabledTimes) {
          const disabledHours = disabledTimes.disabledHours?.() ?? [];
          const disabledMinutes = disabledTimes.disabledMinutes?.(i) ?? [];

          if (disabledHours.includes(i) || disabledMinutes.includes(j)) {
            continue;
          } else {
            timeSlots.push(`${formatHour(i)}:${formatMinute(j)}`);
          }
        } else {
          timeSlots.push(`${formatHour(i)}:${formatMinute(j)}`);
        }
      }
    }

    return timeSlots;
  }, [disabledTimes, minuteStep]);

  if (timeSlots.length === 0) {
    return (
      <div className="text-sm text-gray-400">
        {t('reservation.create-form.form-input.date.no-time', 'No available time')}
      </div>
    );
  }

  return (
    <div className="grid grid-cols-3 gap-2">
      {timeSlots.map((time) => {
        return (
          <div
            key={time}
            onClick={() => props.onTimeChange?.(time)}
            className={classNames(
              'flex cursor-pointer items-center justify-center rounded px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
              {
                'bg-indigo-600 text-white hover:bg-indigo-400': value.format('HH:mm') === time,
              }
            )}
          >
            {time}
          </div>
        );
      })}
    </div>
  );
};

function isPreviousValid(date: Dayjs) {
  return dayjs().isSame(date, 'date');
}

function formatHour(hour: number): string {
  return hour < 10 ? `0${String(hour)}` : String(hour);
}

function formatMinute(minute: number): string {
  return minute < 10 ? `0${String(minute)}` : String(minute);
}

function currentDayOfWeek(date: Dayjs) {
  const daysOfWeeks: DaysOfWeek[] = [
    DaysOfWeek.Sunday,
    DaysOfWeek.Monday,
    DaysOfWeek.Tuesday,
    DaysOfWeek.Wednesday,
    DaysOfWeek.Thursday,
    DaysOfWeek.Friday,
    DaysOfWeek.Saturday,
  ];
  return daysOfWeeks[date.day()];
}

export default DateInput;
