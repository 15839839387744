import { CategoryStyle, MenuProductItemFragment, MenuSectionItemFragment } from '@app/graphql/types/graphql.ts';
import ProductPickerListStyle from '@app/page/online-order/product-picker/component/product-picker-list-style/product-picker-list-style.tsx';

export default function ProductPickerRoute(props: {
  style: CategoryStyle | undefined;
  sections: readonly MenuSectionItemFragment[];
  products: readonly MenuProductItemFragment[];
  timezone: string;
}) {
  return (
    <>
      {/*Hide style selection*/}
      <ProductPickerListStyle timezone={props.timezone} sections={props.sections} products={props.products} />
    </>
  );
}
