import useHolidays from '@app/page/appointment/appointment-create/logic/use-holidays.ts';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import AlertBanner from '@app/page/appointment/appointment-create/component/alert-banner.tsx';
import { useTranslate } from '@tolgee/react';

interface HolidayTimeOutData {
  duration: number;
  startDate: string;
  endDate: string;
  startHour: string;
  endHour: string;
  reason: string;
}

export default function HolidayTimeOutBanner(props: { branchId: string; timezone: string }) {
  const { t } = useTranslate();
  const { data } = useHolidays(props.branchId);
  const [holidayData, setHolidayData] = useState<HolidayTimeOutData | undefined>();

  useEffect(() => {
    if (!data || data.storefront_holidays.length === 0) {
      return;
    }

    const sevenDayAfter = data.storefront_holidays.filter((item) => {
      const today = moment().tz(props.timezone);
      const startDate = moment(item.startTime).tz(props.timezone);

      return moment(startDate).diff(today, 'days') <= 7;
    });

    if (sevenDayAfter.length == 0) {
      return;
    }

    const nearestDay = sevenDayAfter[0];

    const duration = moment(moment(nearestDay.endTime).tz(props.timezone).format('YYYY-MM-DD')).diff(
      moment(nearestDay.startTime).tz(props.timezone).format('YYYY-MM-DD'),
      'day'
    );

    setHolidayData({
      duration: duration,
      startDate: moment(nearestDay.startTime).tz(props.timezone).locale('de').format('DD/MMM'),
      endDate: moment(nearestDay.endTime).tz(props.timezone).locale('de').format('DD/MMM'),
      startHour: moment(nearestDay.startTime).tz(props.timezone).format('HH:mm'),
      endHour: moment(nearestDay.endTime).tz(props.timezone).format('HH:mm'),
      reason: nearestDay.description,
    });
  }, [data, props.timezone]);

  if (!holidayData) {
    return null;
  }

  const commonDate = holidayData.startDate == holidayData.endDate;
  const isAllDay = holidayData.startHour == '00:00' && holidayData.endHour == '23:59';
  const emptyReason = holidayData.reason.length == 0;

  const alert = [
    t('appointment.appointment-create.alert.alert-1.title', 'Holiday Closure Notice'),
    t(
      'appointment.appointment-create.alert.alert-1.message',
      'Our services will be closed {dateRange} {hourRange} {reason}.',
      {
        reason: emptyReason
          ? ''
          : t('appointment.appointment-create.alert.alert-1.message.reason', 'in observance of {reason}', {
              reason: holidayData.reason,
            }),
        dateRange: commonDate
          ? t('appointment.appointment-create.alert.alert-1.message.date-string.common', 'on {startDate}', {
              startDate: holidayData.startDate,
            })
          : t(
              'appointment.appointment-create.alert.alert-1.message.date-string.separate',
              `from {startDate} to {endDate}`,
              {
                startDate: holidayData.startDate,
                endDate: holidayData.endDate,
              }
            ),
        hourRange: isAllDay
          ? t('appointment.appointment-create.alert.alert-1.message.hour-string.all-day', 'all day')
          : t(
              'appointment.appointment-create.alert.alert-1.message.hour-string.not-all-day',
              `, between {startHour} and {endHour}`,
              {
                startHour: holidayData.startHour,
                endHour: holidayData.endHour,
              }
            ),
      }
    ),
  ];

  return (
    <>
      <AlertBanner title={alert[0]} message={alert[1]} />
    </>
  );
}
