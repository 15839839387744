export interface Service {
  id: string;
  section: string;
  name: string;
  time: string;
  cost: string;
}

export const services: Service[] = [
  { id: 'HPC1', section: 'HANDPFLEGE/HAND CARE', name: 'Maniküre - Handpflege', time: '15 Min', cost: '17€' },
  { id: 'HPC2', section: 'HANDPFLEGE/HAND CARE', name: 'Maniküre mit Lack', time: '25 Min', cost: '25€' },
  { id: 'HPC3', section: 'HANDPFLEGE/HAND CARE', name: 'Manilküre mit Shellac', time: '35 Min', cost: '35€' },
  { id: 'HPC4', section: 'HANDPFLEGE/HAND CARE', name: 'Auffüllen mit Farbacryl', time: '45 Min', cost: '42€' },
  { id: 'HPC5', section: 'HANDPFLEGE/HAND CARE', name: 'Auffüllen mit Shellac', time: '40 Min', cost: '40€' },
  { id: 'HPC6', section: 'HANDPFLEGE/HAND CARE', name: 'Nagelverlängerung Natural', time: '35 Min', cost: '35€' },
  {
    id: 'HPC7',
    section: 'HANDPFLEGE/HAND CARE',
    name: 'Naturnagelverstärkung Ohne Verlängerung',
    time: '30 Min',
    cost: '30€',
  },

  { id: 'FPC1', section: 'FUSSPFLEGE/FOOT CARE', name: 'Pediküre - Fußpflege', time: '35 Min', cost: '35€' },
  { id: 'FPC2', section: 'FUSSPFLEGE/FOOT CARE', name: 'Pediküre mit Lack', time: '45 Min', cost: '43€' },
  {
    id: 'FPC3',
    section: 'FUSSPFLEGE/FOOT CARE',
    name: 'Fußpflege mit Schellack Extra combo',
    time: '50 Min',
    cost: '52€',
  },
  {
    id: 'FPC4',
    section: 'FUSSPFLEGE/FOOT CARE',
    name: 'Auffüllen mit French, Faber, Ombre',
    time: '40 Min',
    cost: '40€',
  },
  { id: 'FPC5', section: 'FUSSPFLEGE/FOOT CARE', name: 'Chrom', time: '50 Min', cost: '50€' },
  { id: 'FPC6', section: 'FUSSPFLEGE/FOOT CARE', name: 'Auffüllen Natur', time: '30 Min', cost: '30€' },

  { id: 'NLS1', section: 'NAGEL / NAILS', name: 'Nagelverstärkung U18', time: '25 Min', cost: '25€' },
  {
    id: 'NLS2',
    section: 'NAGEL / NAILS',
    name: 'Naturnagelverstärkung Ohne Verlängerung',
    time: '30 Min',
    cost: '30€',
  },
  { id: 'NLS3', section: 'NAGEL / NAILS', name: 'Nagelverlängerung Natural', time: '35 Min', cost: '35€' },
  { id: 'NLS4', section: 'NAGEL / NAILS', name: 'Auffüllen mit Shellac', time: '40 Min', cost: '40€' },
  { id: 'NLS5', section: 'NAGEL / NAILS', name: 'Auffüllen mit Farbacryl', time: '45 Min', cost: '42€' },
  { id: 'NLS6', section: 'NAGEL / NAILS', name: 'Weissverlauf nagel mit Gel/Acyl', time: '45 Min', cost: '40€' },
  { id: 'NLS7', section: 'NAGEL / NAILS', name: 'Auffüllen mit French, Faber, Ombre', time: '40 Min', cost: '40€' },

  {
    id: 'WEL1',
    section: 'WIMPERNVERLÄNGERUNG/EYELASH',
    name: 'Wimpernverlängerung - Nature Look - innerhalb von 2 Wochen',
    time: '1 Std',
    cost: '65€',
  },
  {
    id: 'WEL2',
    section: 'WIMPERNVERLÄNGERUNG/EYELASH',
    name: 'Nature Auffüllen - Nach 2 Wochen',
    time: '1 Std',
    cost: '45€',
  },
  {
    id: 'WEL3',
    section: 'WIMPERNVERLÄNGERUNG/EYELASH',
    name: 'Nature Auffüllen Nach 3 Wochen',
    time: '1 Std',
    cost: '55€',
  },
  {
    id: 'WEL4',
    section: 'WIMPERNVERLÄNGERUNG/EYELASH',
    name: 'Wimpernverlängerung - Volume',
    time: '1 Std',
    cost: '85€',
  },
  {
    id: 'WEL5',
    section: 'WIMPERNVERLÄNGERUNG/EYELASH',
    name: 'Volume Auffüllen - nach 2 Wochen',
    time: '1 Std',
    cost: '55€',
  },
  {
    id: 'WEL6',
    section: 'WIMPERNVERLÄNGERUNG/EYELASH',
    name: 'Volume Auffüllen - Nach 3 Wochen',
    time: '1 Std',
    cost: '65€',
  },
  { id: 'WEL7', section: 'WIMPERNVERLÄNGERUNG/EYELASH', name: 'Mega Volume', time: '1 Std', cost: '98€' },

  {
    id: 'OTH1',
    section: 'OTHER',
    name: 'Pediküre Mit Neueverlängerung/frech/ombre/Farbe',
    time: '1 Std.',
    cost: '65€',
  },
  { id: 'OTH2', section: 'OTHER', name: 'Pediküre Mit Chrom', time: '1 Std.', cost: '60€' },
];
