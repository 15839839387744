import { useQuery } from '@apollo/client';
import { graphql } from '@app/graphql/types';
import { OpeningTimeServiceType } from '@app/graphql/types/graphql.ts';

export const QUERY = graphql(`
    query storefront_openingTimes($branchId: ID!, $filter: OpeningTimeFilter) {
        storefront_openingTimes(branchID: $branchId, filter: $filter) {
            id
            dayOfWeek
            openTime
            closeTime
        }
    }
`);

export function useGetOpenningTime(branchId: string, service: OpeningTimeServiceType) {
  return useQuery(QUERY, {
    variables: {
      branchId,
      filter: {
        service: service,
      },
    },
  });
}
