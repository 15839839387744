import { RouteObject } from 'react-router-dom';
import ProductPickerPage from '@app/page/online-order/product-picker/product-picker-page.tsx';
import OnlineOrderDetail from '@app/page/online-order/detail/online-order-detail.tsx';
import { Layout } from '@app/Layout.tsx';

export const OnlineOrderRouter: RouteObject = {
  path: 'online-order',
  element: <Layout classNames={'bg-white'} />,
  children: [
    {
      path: 'start',
      element: <ProductPickerPage />,
    },
    {
      path: ':onlineOrderID',
      element: <OnlineOrderDetail />,
    },
  ],
};
