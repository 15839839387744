import { useMutation } from '@apollo/client';
import { graphql } from '../../../graphql/types';

const MUTATION = graphql(`
  mutation Storefront_cancelReservation($id: ID!, $reason: CreateCancelReasonInput) {
    storefront_cancelReservation(id: $id, reason: $reason) {
      id
      confirmationStatus
      status
    }
  }
`);

export default function useCancelReservation() {
  return useMutation(MUTATION, {});
}
