import { useEffect, useState } from 'react';
import DestroyButton from '@app/components/destroy-button.tsx';
import { Dialog, DialogPanel } from '@headlessui/react';
import Card from '@app/components/card/card.tsx';
import CardHeader from '@app/components/card/card-header.tsx';
import CardFooter from '@app/components/card/card-footer.tsx';
import PrimaryButton from '@app/components/primary-button.tsx';
import { useTranslate } from '@tolgee/react';
import { CancelReasonEnum, Storefront_TableReservationItemFragment } from '@app/graphql/types/graphql.ts';
import { useForm } from 'react-hook-form';
import { captureException } from '@sentry/react';
import FormItem from '@app/components/form/form-item.tsx';
import CardContent from '@app/components/card/card-content.tsx';
import useCancelReservation from '@app/page/table-reservation-detail/logic/use-update-confirm.ts';

interface Reason {
  reason: CancelReasonEnum[];
  note: string;
}

const cancelReasonOrder = {
  NO_REASON: 1,
  BUSY: 2,
  NO_INTEREST: 3,
  SOFTWARE_ISSUE: 4,
  OTHER: 5,
};
export default function CancelReasonReservationButton(props: {
  onClick: () => void;
  reservation: Storefront_TableReservationItemFragment | null | undefined;
}) {
  const tableReservationId = props.reservation?.id ?? '';

  const [isOpen, setIsOpen] = useState(false);
  const [cancel] = useCancelReservation();
  const { t } = useTranslate();

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  useEffect(() => {
    close();
  }, []);

  const form = useForm<Reason>({
    defaultValues: {
      reason: [],
      note: '',
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });
  const { register, handleSubmit, watch } = form;

  // const reasonCancel: CancelReasonEnum[] = [];
  const onSubmit = async (data: Reason) => {
    try {
      const result = await cancel({
        variables: {
          id: tableReservationId,
          reason: data,
        },
      });
      if (result.data) {
        setIsOpen(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const watchedReasons = watch('reason');
  const noteValue = watch('note', '');

  return (
    <>
      <button
        className={'flex-1 rounded-md bg-red-500 px-2 py-1.5 font-semibold text-white hover:bg-red-600'}
        onClick={open}
      >
        {t('reservation.detail.action.cancel', 'Cancel booking')}
      </button>

      <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={close}>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-screen items-center justify-center p-4 text-center backdrop-brightness-75">
            <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-xl p-6 text-left align-middle transition-all">
              <Card>
                <CardHeader title={t('reservation.detail.action.cancel-dialog.header', 'Cancel reservation')}>
                  {form.formState.errors.reason && (
                    <p className="pt-1 text-xs text-red-500">{form.formState.errors.reason.message}</p>
                  )}
                </CardHeader>
                <CardContent>
                  <form
                    className="space-y-6"
                    onSubmit={() => {
                      handleSubmit(onSubmit);
                    }}
                  >
                    <FormItem title={t('reservation.detail.action.cancel-dialog.reason', 'Reason')}>
                      <div className="grid grid-cols-2">
                        {Object.values(CancelReasonEnum)
                          .sort((a, b) => cancelReasonOrder[a] - cancelReasonOrder[b])
                          .map((reason) => (
                            <div key={reason} className="col-span-2 flex gap-2 truncate px-1 py-2 sm:col-span-1">
                              <input
                                type={'checkbox'}
                                value={reason}
                                {...register('reason', {
                                  required: t(
                                    'reservation.detail.action.cancel-dialog.reason.validation.required',
                                    'Please select at least one reason'
                                  ),
                                })}
                                className="form-checkbox mt-1 rounded text-indigo-600"
                                onChange={(e) => {
                                  const currentReasons = watchedReasons;
                                  if (e.target.checked) {
                                    form.setValue('reason', [...currentReasons, reason]);
                                  } else {
                                    form.setValue(
                                      'reason',
                                      currentReasons.filter((r) => r !== reason)
                                    );
                                  }
                                }}
                              />
                              <span className="text-md">
                                {t(`reservation.detail.action.cancel-dialog.reason.${reason.toLowerCase()}`, reason)}
                              </span>
                            </div>
                          ))}
                      </div>
                    </FormItem>
                    <FormItem
                      // subtitle={`(${String(form.getValues('note').length)}/${String(100)})`}
                      subtitle={`(${String(noteValue.length)}/${String(100)})`}
                      title={t('reservation.detail.action.cancel-dialog.note', 'Note')}
                    >
                      <textarea
                        maxLength={100}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Details"
                        {...register('note', {
                          maxLength: {
                            value: 100,
                            message: t(
                              'reservation.detail.action.cancel-dialog.note.validation.max-length',
                              'Note must be less than 100 characters'
                            ),
                          },
                        })}
                      />
                      {form.formState.errors.note && (
                        <p className="pt-1 text-xs text-red-500">{form.formState.errors.note.message}</p>
                      )}
                    </FormItem>
                  </form>
                </CardContent>
                <CardFooter>
                  <div className="flex w-full flex-col items-center justify-between gap-y-4 sm:flex-row">
                    <DestroyButton
                      onClick={() => {
                        handleSubmit(onSubmit)().catch(captureException);
                      }}
                      className="w-full sm:w-auto"
                    >
                      {t('reservation.detail.action.cancel-dialog.button.cancel', 'Cancel Reservation')}
                    </DestroyButton>
                    <PrimaryButton className="w-full bg-indigo-600 px-6 hover:bg-indigo-400 sm:w-auto" onClick={close}>
                      {t('reservation.detail.action.cancel-dialog.button.dismiss', 'Dismiss')}
                    </PrimaryButton>
                  </div>
                </CardFooter>
              </Card>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
