import { RouteObject } from 'react-router-dom';
import AppointmentCreate from '@app/page/appointment/appointment-create/appointment-create.tsx';
import AppointmentTrack from '@app/page/appointment/appointment-track/appointment-track.tsx';
import { Layout } from '@app/Layout.tsx';

export const AppoitmentRouter: RouteObject = {
  path: 'appointment',
  element: <Layout classNames={'bg-gray-100 min-h-32'} />,
  children: [
    {
      path: 'create',
      element: <AppointmentCreate />,
    },
    {
      path: 'track/:appointmentId',
      element: <AppointmentTrack />,
    },
  ],
};
