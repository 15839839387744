import { graphql } from '@app/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
  query storefront_holidays($branchId: ID!) {
    storefront_holidays(branchId: $branchId) {
      id
      description
      startTime
      endTime
    }
  }
`);

export default function useHolidays(branchId: string) {
  return useQuery(QUERY, {
    variables: {
      branchId,
    },
  });
}
