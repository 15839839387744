import { graphql } from '@app/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
  query storefront_getAppointment($id: ID!) {
    storefront_appointment(id: $id) {
      id
      branchId
      startTime
      message
      confirmationStatus
      branch {
        company {
          settings {
            currency
            timezone
          }
        }
      }
      customerVariant {
        name
        email
        phone
      }
      products {
        id
        pickedProduct {
          id
          title
          code
          description
          menuProduct {
            id
            title
            code
            description
            appointmentMenuProductData {
              duration
            }
          }
          configurations {
            title
            value
            price
          }
        }
      }
    }
  }
`);

export default function useGetAppointment(id: string) {
  return useQuery(QUERY, {
    variables: {
      id,
    },
  });
}
