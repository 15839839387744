import { useQuery } from '@apollo/client';
import { graphql } from '../../../graphql/types';

export const QUERY = graphql(`
  query OpeningTimes($branchId: ID!) {
    storefront_openingTimes(branchID: $branchId, filter: { service: TABLE_RESERVATION }) {
      dayOfWeek
      openTime
      closeTime
    }

    storefront_tableReservationConfiguration(branchID: $branchId) {
      id
      status
      pause
      defaultBookingTimeDuration
      hourBlocking
      reservationLeadMinutes
      reservationTraitMinutes
      branch {
        company {
          settings {
            timezone
          }
        }
      }
    }
  }
`);

export function useGetBranchOpeningTime(branchId: string) {
  return useQuery(QUERY, { variables: { branchId: branchId } });
}
