import { useMutation } from '@apollo/client';
import { graphql } from '@app/graphql/types';

const MUTATION = graphql(`
  mutation storefront_cancelAppointment($id: ID!, $reason: CreateCancelReasonInput) {
    storefront_cancelAppointment(id: $id, reason: $reason) {
      id
    }
  }
`);

export default function useCancelAppointment() {
  return useMutation(MUTATION, {});
}
