import { MenuProductItemFragment, ProductConfigurationType } from '@app/graphql/types/graphql.ts';
import { getFragmentData } from '@app/graphql/types';
import {
  MenuProductConfigurationFragment,
  MenuProductConfigurationValueFragment,
} from '@app/page/online-order/model/menu-product-fragment.ts';
import CurrencyView from '@app/components/price/currency-view.tsx';
import ProductLabelIcon from '../product-label-icon';
import CDNLink from '@app/utils/cdn-link';
import DialogButton from '@app/components/dialog-button';
import ProductConfigurationSetup from '../product-configuration-setup';
import ProductAddButton from '../product-add-button';
import { useTranslate } from '@tolgee/react';

const ProductListStyle = (props: { product: MenuProductItemFragment }) => {
  const firstImage = props.product.images.length > 0 ? props.product.images[0] : null;
  const configurations = props.product.configurations.map((config) => {
    const configuration = getFragmentData(MenuProductConfigurationFragment, config);

    return {
      ...configuration,
      values: configuration.values.map((value) => {
        return getFragmentData(MenuProductConfigurationValueFragment, value);
      }),
    };
  });

  const singlePrice = configurations.length === 1 && configurations[0].values.length === 1;
  const basePrices =
    configurations
      .find((config) => config.type === ProductConfigurationType.Base)
      ?.values.map((value) => value.price) ?? [];

  const minBasePrice = Math.min(...basePrices);
  const { t } = useTranslate();
  return (
    <DialogButton content={<ProductConfigurationSetup product={props.product} />}>
      <div
        className="flex w-full bg-white rounded-md overflow-hidden outline outline-1 outline-gray-200 hover:bg-blue-50 sm:h-44 ">
        {firstImage && (
          <div className="md:1/4 hidden h-full sm:block sm:w-1/3">
            <img src={CDNLink(firstImage)} className="h-full w-full object-cover max-md:min-w-36" alt="product-image" />
          </div>
        )}

        <div className="flex w-full flex-col space-y-2 px-6 py-4 pr-2">
          <p className="flex items-center gap-1 text-lg font-semibold">
            <span>{props.product.code}. {props.product.title}{' '}</span>
            <span className="text-sm font-medium text-indigo-500 italic">{props.product.allergics}</span>
          </p>
          <p className="line-clamp-2 text-xs italic text-gray-500">{props.product.description}</p>

          <div className="flex flex-row">
            {props.product.labels?.sort().map((label) => {
              return (
                <div key={label} className="h-5 w-5">
                  <ProductLabelIcon key={label} label={label} />
                </div>
              );
            })}
          </div>

          <div className="flex-grow" />

          <div className="flex w-full items-center justify-between space-x-2 text-lg font-medium text-gray-600">
            <ProductAddButton product={props.product} />
            {singlePrice ? (
              <CurrencyView price={minBasePrice} />
            ) : (
              <div>
                {t('online-order.picker-page.product-list-style.start-from', 'Start from')}
                <CurrencyView price={minBasePrice} />
              </div>
            )}
          </div>
        </div>

        <div className="flex-grow" />
      </div>
    </DialogButton>
  );
};

export default ProductListStyle;
